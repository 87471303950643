import { useRef, useState } from "react";
const Home = () => {
  const [plus1, setPlus1] = useState(false);
  const [plus2, setPlus2] = useState(false);
  const [plus3, setPlus3] = useState(false);
  const [plus4, setPlus4] = useState(false);
  const [plus5, setPlus5] = useState(false);

  return (
    <div className="overflow-hidden">
      <div className="relative w-full">
        <a href="/">
          <img
            src="/images/logo.png"
            className="absolute top-4 lg:top-10 right-4 lg:right-20 w-[100px] lg:w-[227px]"
            alt=""
          />
        </a>
        <div className="absolute bottom-[-80px] lg:bottom-[-160px] -translate-x-1/2 left-1/2">
          <img
            src="/images/echilibru.png"
            alt=""
            className="w-[370px] mx-auto block mb-[30px] lg:mb-[0]"
          />

          <img
            src="/images/formula.png"
            alt=""
            className="absolute top-[100px] right-[-60px] w-[100px] lg:w-[210px]"
          />
          <img
            src="/images/box.png"
            className="w-[250px]  lg:w-[90%] max-w-[672px] mx-auto"
            alt=""
          />
        </div>

        <img
          src="/images/bk-mobile.png"
          alt="header"
          className="block w-full lg:hidden h-[330px] object-cover"
        />
        <img
          src="/images/header-bk.png"
          alt="header"
          className="hidden w-full lg:block"
        />
      </div>
      <div className="w-full pt-32 text-center lg:pt-48 ">
        <h1 className="text-[#073481] font-bold text-2xl">Recomandat:</h1>
        <div>
          <div className="lg:w-[800px] mx-auto w-11/12 overflow-hidden">
            <div className="relative w-fit">
              <img
                src="/images/r1.png"
                alt=""
                className="max-w-[500px] w-[90%] mx-auto block float-left"
              />
              <img
                onClick={() => {
                  setPlus1(!plus1);
                }}
                src="/images/plus.svg"
                alt=""
                className="absolute right-0 top-[15px] lg:top-[27px] cursor-pointer"
              />
            </div>
            {plus1 && (
              <p className="text-[#03183D] text-xl float-right text-left w-[90%] lg:pr-16 relative lg:top-[-10px]">
                MagneB6® contribuie la cresterea rezistentei organismului in
                fata factorilor de stres, reglând funcționarea normală a
                sistemului nervos.
              </p>
            )}
          </div>
        </div>
        <div>
          <div className="lg:w-[800px] mx-auto w-11/12 overflow-hidden">
            <div className="relative w-fit">
              <img
                src="/images/r2.png"
                alt=""
                className="max-w-[500px] w-[90%] mx-auto block float-left"
              />
              <img
                onClick={() => {
                  setPlus2(!plus2);
                }}
                src="/images/plus.svg"
                alt=""
                className="absolute right-0 top-[27px] cursor-pointer"
              />
            </div>
            {plus2 && (
              <p className="text-[#03183D] text-xl float-right text-left w-[90%] lg:pr-16 relative lg:top-[-10px]">
                MagneB6® suplimentează aportul de magneziu, indispensabil bunei
                funcționari a mușchilor.
              </p>
            )}
          </div>
        </div>
        <div>
          <div className="lg:w-[800px] mx-auto w-11/12 overflow-hidden">
            <div className="relative w-fit">
              <img
                src="/images/r3.png"
                alt=""
                className="max-w-[392px] w-[90%] mx-auto block float-left"
              />
              <img
                onClick={() => {
                  setPlus3(!plus3);
                }}
                src="/images/plus.svg"
                alt=""
                className="absolute right-0 top-[27px] cursor-pointer"
              />
            </div>
            {plus3 && (
              <p className="text-[#03183D] text-xl float-right text-left w-[90%] lg:pr-16 relative lg:top-[-10px]">
                MagneB6® susține funcționarea sistemului nervos în situații de
                suprasolicitare, reducând semnificativ simptome precum oboseala
                și epuizarea.{" "}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-[1000px] w-11/12">
        <img src="/images/icon.png" alt="" className="w-full" />
      </div>
      <div className="w-full mx-auto max-w-[1200px] hasbk">
        <div className="items-center w-full p-6 space-x-6 lg:p-20 lg:flex">
          <div className="w-5/12 mx-auto lg:w-2/12">
            <img src="/images/stiati.png" />
          </div>
          <div className="w-10/12 mx-auto">
            <p className="text-xl font-[300] text-white">
              <strong>Magneziul este esențial</strong> pentru: producerea
              energiei, funcționarea mușchilor, funcționarea nervoasă, structura
              oaselor și a dinților, , replicarea ADN-ului, sinteza ARN-ului și
              a proteinelor.
              <br />
              <br />
              <strong>VITAMINA B6</strong> (piridoxina) favorizează absorbția si
              fixarea magneziului la nivel celular si asigură funcționarea
              normală a funcțiilor fiziologice (sistemul nervos central și
              metabolismul). Formula originala Magne B6 ce conține magneziu și
              vitamina B6 este cu 24% mai eficientă în reducerea stresului față
              de formulele ce conțin doar magneziu.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full relative mt-9 lg:mt-0  py-4 px-4 lg:pb-28 mx-auto max-w-[1200px] lg:border-2 lg:border-[#06318C] rounded-lg ">
        <img
          src="/images/formula-originala.png"
          className="w-full lg:w-8/12 max-w-[665px] mx-auto"
          alt=""
        />
        <div className="w-full mx-auto lg:w-9/12">
          <p className="text-[#06318C] font-[300]">
            <strong>Ingrediente active:</strong>
            <br /> Magneziu 48 mg, sub formă de lactat de magneziu anhidru 470mg
            şi clorhidrat de piridoxină (vitamina B6) 5 mg.
          </p>
        </div>
        <div className="lg:absolute lg:bottom-[-180px] lg:-translate-x-1/2 lg:left-1/2 lg:max-w-[500px] flex items-center justify-between lg:space-x-6">
          <img src="/images/plus-1.png" className="w-4/12 max-w-[200px]" />
          <img src="/images/plus-2.png" className="w-4/12 max-w-[200px]" />
          <img src="/images/plus-3.png" className="w-4/12 max-w-[200px]" />
        </div>
      </div>
      <div className="w-full pt-6 lg:pt-[250px]">
        <div className="w-full box-blue min-h-[700px] ">
          <div className="py-6 text-white text-xl lg:text-[44px] text-center">
            Magne B6 este recomandat in tratamentul deficitului de magneziu.
          </div>
          <div className="mt-10 w-11/12 mx-auto max-w-[1000px] text-center font-[300] text-white text-xl lg:text-[44px]  border border-white rounded-xl py-6 uppercase">
            URMATOARELE SIMPTOME POT INDICA
            <br /> UN DEFICIT DE MAGNEZIU:
          </div>
          <div className="w-11/12 max-w-[900px] pt-20 mx-auto">
            <img src="/images/explain.png" className="w" />
          </div>
        </div>
      </div>
      <div className="py-8 text-[#06318C]">
        <div className="w-full lg:w-8/12 max-w-[665px] relative mx-auto">
          <img
            src="/images/afla.png"
            className="hidden w-full mx-auto lg:block"
            alt=""
          />
          <img
            onClick={() => {
              setPlus5(!plus5);
            }}
            src="/images/plus.svg"
            alt=""
            className="absolute right-0 lg:-translate-y-1/2 cursor-pointer lg:-right-10 lg:top-1/2 top-[50px]"
          />
          <img
            src="/images/alfa-mobile.png"
            className="w-11/12  max-w-[665px] mx-auto  block lg:hidden"
            alt=""
          />
        </div>

        <div className="w-9/12 mx-auto lg:flex">
          <div className="flex flex-row-reverse items-start w-full lg:text-right lg:flex-row lg:w-6/12">
            <p className="w-9/12 pt-8 text-2xl font-[300]">
              {plus5 && <strong>Stresul cronic</strong>}
            </p>
            <img
              src="/images/i1.png"
              alt=""
              className="w-[100px] lg:w-[170px]"
            />
          </div>
          <div className="flex flex-row-reverse items-start w-full pt-8 text-left lg:pt-0 lg:w-6/12">
            <p className="w-9/12 pt-8 text-2xl font-[300]">
              {plus5 && (
                <strong>
                  Efortul fizic susținut <br />
                  Suprasolicitarea
                </strong>
              )}
            </p>
            <img
              src="/images/i2.png"
              alt=""
              className="w-[100px] lg:w-[170px]"
            />
          </div>
        </div>
        <div className="w-9/12 mx-auto lg:flex">
          <div className="flex flex-row-reverse items-start w-full lg:text-right lg:flex-row lg:w-6/12">
            <p className="w-9/12 pt-8 text-2xl font-[300]">
              {plus5 && (
                <>
                  <strong>Unele afectiuni:</strong>
                  <br /> - hipertensiunea <br />- diabetul de tip 2 <br />-
                  migrenele
                </>
              )}
            </p>
            <img
              src="/images/i3.png"
              alt=""
              className="w-[100px] lg:w-[170px]"
            />
          </div>
          <div className="flex flex-row-reverse items-start w-full pt-8 text-left lg:pt-0 lg:w-6/12">
            <p className="w-9/12 text-2xl font-[300]">
              {plus5 && (
                <>
                  <strong>Utilizarea unor medicamente:</strong> - laxative
                  <br /> - anticoncepționalele <br />- terapia de hormonală de
                  substituție <br />- diureticele <br />- sedativele
                </>
              )}
            </p>
            <img
              src="/images/i4.png"
              alt=""
              className="w-[100px] lg:w-[170px]"
            />
          </div>
        </div>
      </div>
      <div className="w-11/12 relative py-4 px-4 pb-6 mx-auto max-w-[1200px] border-2 border-[#06318C] rounded-lg ">
        <div className="relative mx-auto text-center w-8/12 max-w-[890px] ">
          <img
            src="/images/ce-poate.png"
            className="hidden w-full mx-auto lg:block"
            alt=""
          />
          <img
            src="/images/ce-poate-mobile.png"
            className="w-11/12 max-w-[665px] mx-auto lg:hidden"
            alt=""
          />

          <img
            onClick={() => {
              setPlus4(!plus4);
            }}
            src="/images/plus.svg"
            alt=""
            className="absolute -translate-y-1/2 cursor-pointer -right-10 top-1/2"
          />
        </div>
        {plus4 && (
          <div className="w-9/12 mx-auto text-[#06318C]">
            <ul className="flex flex-wrap justify-between w-full text-[21px] ">
              <li className="px-4 py-2 lg:w-6/12 lg:text-right">
                Funcționarea creierului
              </li>
              <li className="px-4 py-2 lg:w-6/12">Sistemul digestiv</li>
              <li className="px-4 py-2 lg:w-6/12 lg:text-right">
                Aparatul cardiovascular
              </li>
              <li className="px-4 py-2 lg:w-6/12">
                Funcționarea nervilor și a mușchilor
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="w-full relative py-4 px-4 pb-8 mx-auto max-w-[1200px] pt-8 text-center  ">
        <p className="text-[28px] text-center">
          Intreaba doctorul tau cum poti preveni deficitul de magneziu, pentru a
          <br />
          reduce simptomele de oboseala si stres asociate acestuia.
        </p>
        <br />
        <br />
        <h3 className="text-[#06318C] text-2xl lg:text-[36px] font-bold">
          RECOMANDAT:
        </h3>
        <div className="max-w-[500px] mx-auto flex w-full items-center justify-between lg:space-x-6">
          <img src="/images/adulti.png" className="w-4/12 lg:w-[160px]" />
          <img src="/images/adolescenti.png" className="w-4/12 lg:w-[160px]" />
          <img src="/images/copii.png" className="w-4/12 lg:w-[160px]" />
        </div>
      </div>
      <div className="mb-16 bk-doza">
        <h2>DOZA RECOMANDATĂ:</h2>
        <ul>
          <li className="w-6/12 text-right ">Adulţi:</li>
          <li className="w-6/12 text-left ">
            <strong>6-8 drajeuri zilnice</strong>
            <br /> fracționat în 2-3 prize
          </li>
        </ul>
        <ul>
          <li className="w-6/12 text-right">
            Copii cu vârsta peste 6 ani și adolescenți:
          </li>
          <li className="w-6/12 text-left ">
            <strong>4-6 drajeuri zilnice fracționat</strong> <br />
            în 2-3 prize, administrate în timpul meselor
          </li>
        </ul>
        <ul>
          <li className="w-full text-center">
            La copii cu vârsta sub 6 ani se recomandă alte concentraţii şi forme
            farmaceutice, adecvate vârstei
          </li>
        </ul>
        <p className="text-center">
          Durata obişnuită a tratamentului este de o lună. Dacă simptomele nu se
          ameliorează <br />
          după o lună de tratament, nu este utilă continuarea acestuia.
        </p>
      </div>
      <div className="bk-gama">
        <h2 className="h-[138px] text-center  text-white text-2xl  lg:text-[44px] flex items-center  justify-center">
          Vezi si alte produse din gama Magne B6:
        </h2>
        <div className="w-11/12 max-w-[1200px] space-y-8 lg:space-y-0 mx-auto lg:flex justify-between pt-24">
          <div className="w-full lg:w-6/12">
            <div className="lg:h-[350px] flex items-center">
              <img
                src="/images/pack1.png"
                alt=""
                className="w-[360px] mx-auto"
              />
            </div>
            <img src="/images/btn1.png" alt="" className="w-[290px] mx-auto" />
          </div>
          <div className="w-full lg:w-6/12">
            <div className="lg:h-[350px] flex items-center">
              <img
                src="/images/pack2.png"
                alt=""
                className="w-[360px] mx-auto"
              />
            </div>
            <img src="/images/btn2.png" alt="" className="w-[290px] mx-auto" />
          </div>
        </div>
      </div>
      <div className="w-11/12 max-w-[1200px] mx-auto lg:flex justify-between py-24  text-sm lg:text-base font-[300]">
        Acesta este un medicament. Citiți cu atenție prospectul. Dacă apar
        manifestări neplăcute, adresați-vă medicului sau farmacistului. Magne B6
        este indicat in prevenirea şi tratamentul deficitului de magneziu la
        adulţi, adolescenţi şi copii cu vârsta cuprinsă între 6-12 ani.
        <br />
        <br />
        Contraindicații: Hipersensibilitate la lactat de magneziu dihidrat,
        clorhidrat de piridoxină sau la <br /> oricare dintre excipienți.
        Insuficiență renală severă. Asociere cu levodopa. <br />
        Atenționări: Sunt necesare precauții în caz de insuficiență renală
        moderată, infecții urinare, în caz de asociere a deficitului de calciu.{" "}
        <br />
        Sarcina și alăptarea: Magneziul trebuie utilizat în timpul sarcinii
        numai dacă este necesar <br /> Viza de publicitate nr: xxxx/ xx.xx.xxx
      </div>
    </div>
  );
};
export default Home;
